<template>
	<div>
		<headerTop></headerTop>
		<headerMin></headerMin>
		<navlist :state="5"></navlist>
		<div class="content">
			<div class="crumbs">首页>关于我们</div>
			<div v-html="infor"></div>
		</div>
		<div class="bg1">
			<publicBottom></publicBottom>
		</div>
	</div>
</template>

<script>
	import navlist from "@/components/home_repect/navlist.vue"
	import headerTop from "@/components/public/public_headerTop.vue";
	import headerMin from "@/components/index/index_header_min";
	import publicBottom from "@/components/public/public_bottom";
	export default{
		name:"aboutUs",
		components:{
			navlist,
			headerTop,
			headerMin,
			publicBottom
		},
		created(){
			this.getAuoutUsinfor();
		},
		data(){
			return{
				infor:"",
			}
		},
		methods:{
			getAuoutUsinfor(){
				this.$axios.post("/api/public/getSysconf").then(res=>{
					if(res.data.code==1){
						this.infor=res.data.data.aboutus;
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.content {
		width: 1200px;
		margin: 0 auto;
		.crumbs {
			font-size: 14px;
			margin-top: 20px;
			margin-bottom: 10px;
		}
	}
	
</style>
